#lunches {
  padding-top: 108px;
  padding-bottom: 54px;
}

.lunches-container {
  padding: 8px;
  max-width: 800px;
  margin: auto;
}

.lunch-item-price {
  font-family: open_sansregular;
  font-size: 14px;
  text-align: end;
}

.lunch-item-price-bold {
  font-family: bree_serifregular;
  font-size: 18px;
}
