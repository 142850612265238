.home-image-container {
  overflow: hidden;
  position: fixed;
  text-align: center;
  width: 100%;
  height: 100%;
}

.home-image {
  width: 105%;
  height: 105%;
  object-fit: cover;
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  filter: blur(5px);
}

.home-button-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 2;
}

.home-button-container a {
  text-decoration: none;
}

.home-button {
  flex: 0 1 auto;
  font-size: 30px;
  background-color: #6DBF53;
  color: #FFF;
  padding: 8px 12px;
  margin: 4px;
  font-family: bree_serifregular;
  border-radius: 5px;
  width: 150px;
}

.home-button-image {
  width: 30px;
  margin-left: 8px;
  margin-right: 12px;
  margin-top: 8px;
}

.home-button-text {

  margin-left: 2px;
  margin-top: 1px;
  position: absolute;
}
