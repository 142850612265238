body {
  background-color: #F5F5F5;
}

@font-face {
  font-family: 'open_sansbold';
  src: url('fonts/opensans-bold-webfont.woff2') format('woff2'),
       url('fonts/opensans-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'open_sansregular';
  src: url('fonts/opensans-regular-webfont.woff2') format('woff2'),
       url('fonts/opensans-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'bree_serifregular';
  src: url('fonts/breeserif-regular-webfont.woff2') format('woff2'),
       url('fonts/breeserif-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

.menu-card {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  border-radius: 5px;
  padding: 8px;
  background-color: white;
  position: relative;
  margin-bottom: 8px;
  min-height: 55px;
}

.menu-title {
  font-family: bree_serifregular;
  font-size: 36px;
}

.menu-subtitle {
  font-family: bree_serifregular;
  font-size: 28px;
  margin-bottom: 24px;
  margin-top: 24px;
  text-align: center;
}

.menu-text {
  font-family: open_sansregular;
  font-size: 15px;
  margin: 12px 0px;
}

.menu-item-title {
  font-family: bree_serifregular;
  font-size: 22px;
}

.menu-item-text {
  font-family: open_sansregular;
  font-size: 14px;
  white-space: pre-wrap;
}

.logo {
  margin-top: 8px;
  margin-bottom: 8px;
}

