#heading {
  position: fixed;
  width: 100%;
  z-index: 2;
}

.header {
  background-color: #6DBF53;
}

.banner {
  color: #FFF;
  background-color: #AF0B0B;
  text-align: start;
  padding: 8px;
  font-family: open_sansregular;
}
