#buffets {
  padding-top: 108px;
  padding-bottom: 54px;
}

.buffets-container {
  padding: 8px;
  max-width: 800px;
  margin: auto;
}

.buffet-item-price {
  font-family: bree_serifregular;
  font-size: 18px;
  text-align: end;
  position: absolute;
  right: 8px;
  bottom: 8px;
  margin-top: 22px;
}
