#nav {

}

.nav {
  background-color: rgba(0,0,0,0.95);
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 3;
  top: 0;
}

.nav-hidden {
  display: none;
}

.nav-button {
  position: fixed;
  top: 14px;
  right: 16px;
  cursor: pointer;
  z-index: 4;
}

.nav-button-hidden {
  display: none;
}

.nav-button-image {
  width: 40px;
}

.nav-button-image-hidden {
  display: none;
}

.nav-links {
  color: white;
  text-align: center;
  font-family: bree_serifregular;
  font-size: 36px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 75%;
}

.nav-links a {
  text-decoration: none;
}

.nav-link {
  flex: 0 1 auto;
  margin: 8px;
  color: white;
}
