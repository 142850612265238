#about {
  padding-top: 107px;
  padding-bottom: 80px;
}

.about-container {
  padding: 8px;
  max-width: 800px;
  margin: auto;
}

.platters {
  width: 100%;
  height: 350px;
  object-fit: cover;
}

.gradient {
  width: 100%;
  height: 100px;
  margin-top: -80px;
}

.about-title {
  font-family: bree_serifregular;
  font-size: 36px;
  margin-top: -30px;
}

.about-text {
  font-family: open_sansregular;
  font-size: 15px;
  margin: 12px 0px;
}

.about-text-opening {
  font-family: open_sansregular;
  font-size: 15px;
}

.about-text-bold {
  font-family: open_sansbold;
  font-size: 15px;
}

td {
  padding: 0px;
  width: 50%;
}

table {
  width: 100%;
}
