#footer {
  background-color: #6DBF53;
  color: #FFF;
  position: fixed;
  bottom: 0px;
  width: 100%;
  font-family: open_sansregular;
  z-index: 2;
}

#footer-bottom {
  background-color: #6DBF53;
  color: #FFF;
  width: 100%;
  position: absolute;
  font-family: open_sansregular;
}

.details {
  text-align: start;
  margin: 8px;
  float: left;
}

.details a {
  color: white;
}

.hours {
  text-align: end;
  margin: 8px;
  float: right;
}

.hours a {
  color: white;
}