#menus {
  padding-top: 108px;
  padding-bottom: 54px;
}

.menus-container {
  max-width: 800px;
  margin: auto;
  padding: 8px;
}

.menu-button {
  width: calc(100vw - 16px);
  height: calc(100vw - 16px);
  margin-bottom: 8px;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  box-shadow: inset 0 88px 69px rgba(0,0,0,0.95);
  max-width: 800px;
  max-height: 360px;
  margin: 0px auto 8px auto;
}

.menus-container a {
  text-decoration: none;
}

.menu-button-text {
  font-family: bree_serifregular;
  font-size: 35px;
  margin: 16px;
  color: white;
  z-index: 1;
  position: relative;
}

.menu-button-image-container {
  width: 100%;
  height: 100%;
  box-shadow: inset 0 88px 69px rgba(0,0,0,0.9);
}

.menu-button-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: -2;
}