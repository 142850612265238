.lunches-menu-button {
  width: calc(100vw - 16px);
  margin-bottom: 8px;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  max-width: 800px;
  margin: 0px auto 8px auto;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
  background-color: white;
  z-index: -3;
  display: flex;
  align-items: center;
}

.lunches-menu-button-text {
  font-family: bree_serifregular;
  font-size: 24px;
  margin: 16px;
  z-index: 1;
  position: relative;
  color: black;
  flex: 0 1 auto;
  width: 100%;
}

.material-icons {
  font-size: 34px;
  margin-right: 8px;
  color: black;
}
